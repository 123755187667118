.pagination {
}
.internal-body-general {
	.page-numbers {
		li {
			list-style: none;
			display: inline-block;
			margin-right: 20px;
		}
	}
}

// INTERNAL
@include bp(eight-column){
}

@include bp(twelve-column){
}

@include bp(sixteen-column){
}

