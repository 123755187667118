footer {
	background: $c-green;
	color: white;
	font-family: 'oxy-reg';
	position: relative;
	padding: 0;
	.decor-grass-footer {
		display: none;
		background: url('../svg/decor-lines-slider.svg') 0 0 no-repeat;
		background-size: 100%;
		width: 100%;
		height: 80px;
		position: absolute;
		z-index: 9;
		top: -20px;
	}
	.footer-columns {
		padding: 20px;
		margin: 0 auto;
		max-width: 320px;
		@include bp(eight-column) {
			max-width: 640px;
			padding-top: 40px;
			&.wrapper {
				display: flex;
				justify-content: space-between;
			}
		}
		@include bp(twelve-column) {
			max-width: none;
			.address-row {
				max-width: 720px;
				margin: 0 auto;
			}
			&.wrapper {
				display: block;
			}
			.footer-row {
				padding-top: 20px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				.address {
					width: 30%;
					text-align: left;
				}
				.social-icons {
					width: 150px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					a {
						display: block;
						margin-bottom: 10px;
						svg {
							display: block;
							margin: 0;
						}
					}
				}

				&.awards {
					display: flex;
					.footer-image {
						width: 25%;
						margin: 0;
						padding: 0 25px;
					}
				}
			}
		}

	}
	.footer-col {
		margin: 0 0 10px;
	}
	.footer-colophon {
		padding: 15px 0;
		background-color: $c-brown;
	}
	.col-item {
		margin-bottom: 10px;
	}
	h1,h2 {
		margin: 0;
		line-height: 1.2;
	}
	h2 { font-family: 'oxy-bold'; }
	p {
		line-height: 1.4;
		margin: 5px 0;
		color: #effdd3;
	}
	.address {
		margin: 0 0 35px;
		h1 {
			text-transform: uppercase;
			font-size: 18px;
			font-family: 'arsenal-bold';
		}
	}
	.col-menu {
		&:first-of-type {
			text-transform: uppercase;
			font-family: 'arsenal-bold';
		}
		li:not(:first-of-type) {
			display: none;
		}
	}
	.icon {
		width: 45px;
		height: 45px;
		margin-right: 15px;
	}
	.icon-google {
		background: #ece4bc;
		padding: 5px;
		border-radius: 2px;
		fill: $c-green;
	}
	.payment-icons {
		@include pie-clearfix;
		svg {
			height: 20px;
			float: left;
			margin-right: 10px;
		}
		.discover { width: 45px; }
		.visa { width: 25px; }
		.mastercard { width: 25px; }
		.amex { width: 45px; }
	}
	.footer-col.awards {
		img {
			width: 150px;
			display: block;
			margin-bottom: 20px;
		}
	}
}
.footer-colophon {
	p {
		color: white;
		font-size: 14px;
	}
	.logo-trimark {
		display: inline-block;
		text-indent: -999em;
		background: url('../svg/logo-trimark.svg') 0 0 no-repeat;
		background-size: contain;
		width: 80px;
		height: 20px;
		position: relative;
		top: 5px;
		margin: 0 0 0 3px;
	}
}


.footer-row {
	&.awards {
		.footer-image {
			max-width: 240px;
			margin: 0 auto 20px;
			img {
				display: block;
			}
		}
	}
}



@include bp(eight-column) {
	.footer-trigger {
		display: none;
	}
	footer {
		display: block;
		.footer-col {
			width: 50%;
			float: left;
		}
	}
	.col-item {
		margin-bottom: 50px;
	}
}

@include bp(twelve-column) {
	footer {
		.decor-grass-footer {
			display: block;
		}
		.footer-columns .address-row {
			max-width: 960px;
		}
		.col-menu {
			li:not(:first-of-type) {
				display: block;
				text-transform: none;
				font-family: 'oxy-reg';
				font-size: 18px;
				color: #effdd3;
			}
			li {
				font-size: 22px;
				&:hover {
					text-decoration: underline;
					color: white;
				}
			}
		}
		.footer-col {
			width: 25%;
			&.awards img {
				width: 220px;
				margin-bottom: 40px;
			}
		}
	}
	.footer-colophon {
		p:first-of-type {
			float: left;
		}
		p:last-of-type {
			float: right;
		}
	}
}

@include bp(sixteen-column) {
	footer {
		.address {
			h1 {
				font-size: 26px;
			}
			h2 {
				font-size: 38px;
			}
		}
		.col-menu {
			li:not(:first-of-type) {
				font-size: 22px;
			}
			li {
				font-size: 26px;
			}
		}
	}
}






