.internal-header {
	color: rgba(white, 0);
	height: 200px;
	background-size: cover;
}
.testimonial-container {
	display: none;
}

.internal-body-general {
	width: 100%;
	float: none;
}
.body-container {
	padding: 30px 0;
}

// SIDEBAR
aside.sidebar {
	width: 100%;
	float: none;
	margin: 30px 0;
	.wrapper {
		padding: 0;
	}
	.sidebar-accreds {
		text-align: center;
		margin: 30px 0 0;
	}
	.sidebar-accreds img {
		display: inline-block;
		position: relative;
		left: 25px;
	}
}
.recent-blog-posts { // ul
	list-style: none;
	padding: 0;
	li {
		background: #eee9d1;
		font-family: 'arsenal-bold';
		margin-bottom: 20px;
		font-size: 16px;
		text-transform: uppercase;
		position: relative;
		&:hover {
			background: #effdd3;
			box-shadow: 0 3px 0 0 $c-green;
		}
		&:after {
			content: "»";
			position: absolute;
			right: 10px;
			top: 20px;
		}
		a {
			height: 100%;
			display: block;
			padding: 20px 15px;
		}
	}
}

.internal-body-general {
	// TYPOGRAPHY
	h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
		font-family: 'arsenal-bold';
	}
	h1,.h1 {
		font-size: 36px;
		color: $c-brown;
		margin: 0;
	}
	h2,.h2 {
		font-size: 30px;
		color: $c-green;
		margin: 30px 0 0;
	}
	h3,.h3 {
		font-size: 26px;
		color: lighten($c-brown,10%);
		margin: 30px 0 0;
	}
	h4,.h4 {
		font-size: 22px;
		color: #679727;
		margin: 30px 0 0;
	}
	h5,.h5 {
		font-size: 20px;
		color: $c-green;
		margin: 30px 0 0;
	}
	h6,.h6 {
		font-size: 18px;
		color: $c-brown;
		margin: 30px 0 0;
	}
	p,ul {
		line-height: 1.8;
		color: lighten($c-brown,10%);
		font-family: 'oxy-reg';
	}
	a {
		color: #a8a078;
		text-decoration: underline;
		&:hover {
			color: #679727;
		}
	}
	ul {
		padding-left: 40px;
		li {
			list-style-type: disc;
		}
	}
}

.alignright,.alignleft {
	float: none;
	margin: 0 auto 20px;
	display: block;
}

.testimonial-container {
	display: none;
}

// BLOG
.archive_blog-posts {
	padding: 25px 15px 10px;
	position: relative;
	&:not(:last-of-type) {
		border-bottom: 1px solid #a8a078;
	}
	&:not(:last-of-type):after {
		content: "";
		display: block;
		height: 1px;
		background: #a8a078;
		position: absolute;
		bottom: -5px;
		left: 0;
		width: 100%;
	}
	&:hover {
		background: #effdd3;
		box-shadow: 0 2px 0 0 $c-green;
	}
	time {
		display: block;
		margin-bottom: 5px;
	}
	.title {
		text-decoration: none;
		.h2 {
			line-height: 1.2;
			text-transform: uppercase;
		}
	}
	.categories {
		font: normal 14px/2 'arsenal-bold';
		a {
			background: #e9e6d8;	
			color: #679727;
			border: 1px solid #e9e6d8;
			padding: 2px 5px;
			margin: 5px 10px 5px 0;
			text-decoration: none;
			&:hover {
				background: #fff;
				border: 1px solid $c-green;
			}
		}
	}
	p {
		margin: 5px 0;
	}
	.excerpt-read-more {
		text-transform: uppercase;
		font-family: 'arsenal-bold';
		text-decoration: none;
		font-size: 19px;
		color: $c-brown;
		&:hover {
			font-style: italic;
			border-bottom: 1px solid #e9e6d8;
			color: lighten($c-brown,10%);
		}
	}
}
.pagination { // nav
	text-align: right;
	margin: 25px 0 0;
	ul { padding: 0; }
	li {
		border-radius: 40px;
		width: 30px;
		border: 1px solid #eee9d1;
		background: #eee9d1;
		text-align: center;
		a {
			color: #679727;
			text-decoration: none;
			height: 100%;
			display: block;
		}
		&:hover {
			background: #effdd3;
			border-color: $c-green;
		}
	}
	.current { // li
		
	}
}
.single_header-content {
	display: none;
}


// INTERNAL
@include bp(eight-column){
}

@include bp(twelve-column){
	.internal-header {
		height: 270px;
	}

	.internal-body-general {
		width: 65%;
		float: left;
	}

	aside.sidebar {
		width: 375px;
		float: right;
		margin: 0;
	}
	.internal-body-general {
		h1,.h1 {
			font-size: 52px;
		}
		h2,.h2 {
			font-size: 44px;
		}
		h3,.h3 {
			font-size: 35px;
		}
		h4,.h4 {
			font-size: 30px;
		}
		h5,.h5 {
			font-size: 26px;
		}
		h6,.h6 {
			font-size: 22px;
		}
	}
	.alignright {
		float: right;
		margin: 0 0 20px 20px;
	}
	.alignleft {
		float: left;
		margin: 0 20px 20px 0;
	}
	
	// TESTIMONIALS
	.testimonial-container {
		display: block;
		width: 640px;
		background-color: rgba(0,0,0,0.6);
		padding: 30px 80px;
		color: white;
		font-family: 'oxy-reg';
		font-size: 22px;
		text-align: center;
		position: absolute;
		right: 0;
		top: 50px;
		line-height: 1.5;
		&:before,&:after {
			display: block;
			position: absolute;
			height: 50px;
			width: 50px;
			line-height: 1;
			font-size: 130px;
			font-family: 'arsenal-bold';
			-webkit-text-stroke: 2px $c-green;
		}
		&:before {
			content: " “ ";
			left: 10px;
			top: -10px;
		}
		&:after {
			content: " ” ";
			right: 10px;
			bottom: -10px;
		}
		.bx-pager {
			left: 0;
			right: 0;
			bottom: -80px;
			&.bx-default-pager a.active {
				background-color: #000;
			}
		}
	}
	
	// BLOG
	.archive_blog-posts {
		time.h4 {
			font-size: 24px;
		}
		.title {
			.h2 {
				font-size: 35px;
			}
		}
	}
	.single_header-content {
		color: white;
		display: block;
		max-width: 768px;
		margin: 0 auto;
		text-align: center;
		position: relative;
		transform: translateY(-50px);
		top: 40%;
		.title {
			margin: 0;
		}
		p {
			font-family: 'oxy-reg';
			line-height: 1.3;
			margin: 10px 0 0;
		}
		.excerpt-read-more {
			margin: 15px auto 0;
			padding: 2px 10px;
			color: $c-brown;
			font-family: 'arsenal-bold';
			display: block;
			width: 160px;
			text-transform: uppercase;
			background: #eee9d1;
			font-size: 19px;
			border: 1px solid #b4d57f;
		}
	}
}

@include bp(sixteen-column){
	.body-container {
		padding: 60px 0;
		max-width: 1260px;
		margin: 0 auto;
	}
	.sidebar {
		.wrapper {
			padding: 0;
		}
	}
}



// RANDOM ONE-OFFS
.ty-message {
	text-align: center;
	font-family: 'oxy-reg';
	line-height: 1.4;
	background: lighten($c-green,10%);
	color: white;
	padding: 10px;
}

