
// These variables are to denote where our breakpoints live.
// They are in EMs and these values came from (Breakpoint / 16) = em value
$four-column-max: 39.9375em;
$eight-column: 40em;
$eight-column-max: 59.9375em;
$twelve-column: 60em;
$twelve-column-max: 79.9375em;
$sixteen-column: 80em;

$c-green: #076930;
$c-black: #17120c;
$c-brown: #3d280f;
$c-tan: #ada680;