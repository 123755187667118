// fonts
@font-face {
    font-family: 'arsenal-bold';
    src: url('../fonts/arsenal/arsenal-bold-webfont.eot');
    src: url('../fonts/arsenal/arsenal-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/arsenal/arsenal-bold-webfont.woff') format('woff'),
         url('../fonts/arsenal/arsenal-bold-webfont.ttf') format('truetype'),
         url('../fonts/arsenal/arsenal-bold-webfont.svg#roboto-slab-light') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'arsenal-bold-italic';
    src: url('../fonts/arsenal/arsenal-bolditalic-webfont.eot');
    src: url('../fonts/arsenal/arsenal-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/arsenal/arsenal-bolditalic-webfont.woff') format('woff'),
         url('../fonts/arsenal/arsenal-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/arsenal/arsenal-bolditalic-webfont.svg#roboto-slab-light') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'oxy-reg';
    src: url('../fonts/oxy/oxygen-webfont.eot');
    src: url('../fonts/oxy/oxygen-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oxy/oxygen-webfont.woff') format('woff'),
         url('../fonts/oxy/oxygen-webfont.ttf') format('truetype'),
         url('../fonts/oxy/oxygen-webfont.svg#oxy-reg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'oxy-bold';
    src: url('../fonts/oxy/oxygen-bold-webfont.eot');
    src: url('../fonts/oxy/oxygen-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oxy/oxygen-bold-webfont.woff') format('woff'),
         url('../fonts/oxy/oxygen-bold-webfont.ttf') format('truetype'),
         url('../fonts/oxy/oxygen-bold-webfont.svg#oxy-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*============= GENERIC STYLES
==============================================================================*/
*, *:after, *:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
html {
	overflow-x: hidden;
}
body {
	background-color: #fff;
	overflow-x: hidden;
}
a {
	outline: none;
	border: none;
	text-decoration: none;
	color: inherit;
	&:hover { text-decoration: none; }
}
iframe {
	max-width: 100%;
}
img {
	max-width: 100%;
	height: auto; // Force correct aspect ratio in case we need it.
	vertical-align: middle;
}
.section,
section {
	select, input, textarea {
		color: gray;
		font-weight: 400;
		-webkit-font-smoothing: antialiased;
		-webkit-text-size-adjust: none;
		-webkit-backface-visibility: hidden;
		font-size: 93%;
		@include bp(eight-column){ font-size: 95%; }
		@include bp(twelve-column){ font-size: 98%; }
		@include bp(sixteen-column){ font-size: 100%; }
		line-height: 1.25;
	}
}
/*============= PATTERNS
==============================================================================*/
.section,
section {
	position: relative;
	overflow: hidden;
	width: 100%;
	h1 {
		font-family: 'arsenal-bold';
		text-transform: uppercase;
		line-height: 1;
	}
}
// Clearfix
.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}
.clearfix:after {
    clear: both;
}



// TYPOGRAPHY
p {}
h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

// FORMS 
fieldset { border:none; }
input { border-radius: 0; } // Getting rid of all the default border radius that gets applied to inputs on mobile safari

// LISTS
ul,
ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

hr {
	appearance: none;
	display: block;
	width: 100%;
	height: 2px;
	border: none;
	border-top: 2px solid #ede9db;
	border-bottom: 2px solid #ede9db;
	outline: none;
	margin: 65px auto;
}

// WRAPPER
.wrapper {
	margin: 0 auto;
	position: relative;
	width: 100%;
	padding: 0 20px;
	max-width: 1140px;
	@include bp(eight-column, true){}
	@include bp(twelve-column, true){}
	@include bp(sixteen-column, true){}
	@include pie-clearfix();
}

/*============= BUTTONS
==============================================================================*/
.btn {
	text-transform: uppercase;
	text-decoration: none;
	max-width: 100%;
	margin: 15px auto;
	padding: 0 10px;
	text-align: center;
	font-weight: normal;
	font-size: 18px;
	border: none;
	outline: none;
	color: white;
	display: block;
	transition: .3s;
}
.btn-gray {
	background: gray;
	&:hover {
		background: #e5e5e7;
		color:darken(gray,15%);
	}
}
.btn-blue {
	background: blue;
	&:hover {
		background: #ecf5fb;
		color:darken(blue,15%);
	}
}


/*============= BREADCRUMBS
==============================================================================*/

.breadcrumbs {
	color: #60462c;
	font-family: 'arsenal-bold', serif;
	font-size: 18px;
	margin-bottom: 45px;

	a {
		text-decoration: none !important;
	}
}
