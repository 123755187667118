.cta-block {
    text-align: center;
    background: #d1c9a5;
    margin-bottom: 30px;

    .content {
        max-width: 800px;
        padding: 25px 15px;
        margin: 0 auto;

        @media screen and (min-width: 960px) {
            padding: 40px 15px;
        }

        * { color: $c-brown; }

        .title {
            color: #071e0d;
            font: normal 24px/1 'arsenal-bold';

            @media screen and (min-width: 960px) {
                font-size: 33px;
            }
        }

        p {
            line-height: 1.5;
            padding: 0 20px;
        }
        
        ul {
            display: inline-block;
            font: normal 17px/1.4 'arsenal-bold';
            list-style-type: none;
            text-align: left;
            padding: 0;

            li {
                position: relative;
                list-style-type: none;
                padding-left: 15px;

                &:before {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 0;
                    width: 7px;
                    height: 7px;
                    background: $c-green;
                }
            }
        }
    }

    .btn {
        color: white;
        font: normal 21px/1 'arsenal-bold';
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        background: #0d3a16;
        padding: 14px 10px 11px 10px;
        margin: 0;
        
        @media screen and (min-width: 960px) {
            padding: 19px 10px 16px 10px;

            &:after {
                content: '\00BB';
                font-size: 26px;
                margin-left: 10px;
            }
        }

        &:hover {
            color: white;
            background: $c-green;
        }
    }

}