
.close {
	text-align: center;
	display: block;
	position: relative;
	top: 0;
	padding: 10px;
	margin-bottom: 20px;
	color: white;
	background: $c-green;
	font-family: 'arsenal-bold';
}

//--------------------------------//
//--------- MOBILE NAVIGATION ----//
@media only screen and (max-width: 1119px){
	.nav-contain {
		position: absolute;
		top: 0;
		right: -230px;
		width: 230px;
	}
	.primary-navigation {
		clear: both;
		background: white;


		&.active { display: block; }
		&.isMoved {
			margin-top: -150px;
		}
		* { transition: max-height 0.3s; }

		.navigation-list {
			li {
				display: block; width: 100%; position: relative;

				ul { max-height: 0; overflow: hidden; background-color: #fff; transition: max-height 0.3s; }

				a.has-menu {
					.arrow {
						position: absolute; top: 0; right: 0; height: 40px; width: 40px; cursor: pointer;
						background: transparent url(../svg/arrow-dropdown.svg) center no-repeat;
					}
					&.active .arrow {

					}
				}

				ul.active { max-height: 1100px;overflow: auto; }
			}
			a {
				padding: 0px 15px;
				line-height: 2;
				display: block;
				text-decoration: none;
				font-family: 'arsenal-bold';
				text-transform: uppercase;
				color: $c-tan;
			}
			.sub-menu a {
				line-height: 1.5;
				padding: 0px 15px 0 30px;
				text-transform: none;
			}

			a:visisted {
				color: #f1f1f1;
			}
			a.active,
			a:hover {
				background-color: #f1f1f1;
			}

			// Different Levels and Their Styles
			> li > a { // Parents
				font-size: rem(19px);
			}

			> li > ul > li > a { // Children
				font-size: rem(16px);
			}

			> li > ul > li ul > li > a { // Grand-Children
				font-size: rem(14px);
			}

		}

	}
} // end MQ


@include bp(eight-column, true){
	.primary-navigation {
		margin-top: -60px;
	}
}

//---------------------------------//
//--------- DESKTOP NAVIGATION ----//
@media screen and (min-width: 1120px) {
	.priority-nav {
		.menu-item-has-children:hover {
			& > .sub-menu {
				display: block;
			}
		}
		.sub-menu {
			display: none;
			padding: 10px;
			text-transform: none;
		}
	}

	.close {
		display: none;
	}
	.primary-navigation {
		width: 100%;
		position: relative;
		font-size: 22px;
		top: 50px;
		left: 0;
		text-transform: uppercase;
		&.open {
			height: auto;
			display: block;
		}
		&:after {
			display: table; clear: both; content: "";
		}
		.navigation-list {
			// Essential Styles
			width: 100%;
			li { position: relative; transition: all 0.2s; }
			> li { float: left; display: inline; }

			li:hover > ul,
			li.sfHover > ul {
				display: block;
			}

			ul {
				position: absolute;
				display: none;
				top: 80%;
				left: 0;
				z-index: 9999;
				ul {
					top: 0;
					left: 100%;
				}
			}

			a {
				display: block;
				position: relative;
			}
			> li.sfHover,
			> li:hover {
				color: darken(#898c92, 10%);
			}

			> li {
				a {
					text-decoration: none;
					font-size: rem(18px);
					line-height: rem(41px);
					padding: rem(0px 15px);
					font-family: 'arsenal-bold';
					color: $c-tan;
					&:hover {
						color: lighten($c-tan,15%);
					}
				}
				.sub-menu { // Children Menus
					background-color: #fff; padding: rem(15px 0px); width: 235px;
					a {
						font-size: rem(16px);
						border: none;
						transition: background-color 0.3s;
						padding: rem(10px 18px);
						line-height: rem(20px);
						&.sf-with-ul {
							background: transparent url(../svg/sprite-ui-items.svg) right -190px no-repeat;
						}
						&:hover {
							background-color: #f1f1f1;
						}
					}

					.sub-menu {
						padding: rem(0px); min-width: 120%; border-top-width: 1px;
					}
				}
			}

		}
	}
}

// @include bp(sixteen-column){
// 	.primary-navigation {
// 		.navigation-list {
// 			> li {
// 				a {
// 					padding: rem(0px 25px);
// 				}
// 			}
// 		}
// 	}
// }

.priority-nav-trigger,.priority-nav {
	display: none;
}

@media (min-width:1120px){
	#menu-account-menu-mobile {
		display: none;
	}
}

@media (min-width:1120px) and (max-width:1300px){
	.navigation-list > li:not(.priority-item) {
		display: none;
	}
	.priority-nav-trigger {
		display: block;
		position: relative;
		right: -10px;
		top: -5px;
		width: 30px;
		fill: $c-tan;
	}
	.priority-nav {
		display: none;
		position: absolute;
		z-index: 99;
		width: 210px;
	 	left: 19%;
	 	right: 0;
	 	top: 45px;
	 	margin: 0 auto;
	 	background: white;
	 	padding: 15px;
	 	li {
	 		a {
	 			font-family: 'arsenal-bold';
	 			font-size: 16px;
	 			margin-bottom: 10px;
	 			color: $c-tan;
	 		}
	 	}
	}
}





