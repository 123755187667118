.section-slider {
	overflow: visible;
	.bx-wrapper .bx-pager {
		left: 15px;
		&.bx-default-pager {
			.active {
				background: #c1f74e;
			}
		}
	}
}
.slider-1 {
	background: url('../img/heroes/slider-1.jpg') center center no-repeat;
	background-size: cover;
	height: 300px;
}
.slider-2 {
	background: url('../img/heroes/slider-2.jpg') center center no-repeat;
	background-size: cover;
	height: 300px;
}
.slider-3 {
	background: url('../img/heroes/slider-3.jpg') center center no-repeat;
	background-size: cover;
	height: 300px;
}
.slider-content {
	position: absolute;
	bottom: 20px;
	.slide-title {
		color: white;
		text-shadow: 0 5px 1px #000;
		font-size: 30px;
		text-transform: none;
		font-family: 'arsenal-bold-italic';
	}
	.slide-link {
		text-transform: uppercase;
		color: white;
	}
}
.decor-grass-slider {
	display: none;
	background: url('../svg/decor-lines-slider.svg') 0 0 no-repeat;
	background-size: 100%;
	width: 100%;
	height: 80px;
	position: absolute;
	z-index: 9;
	top: -23px;
}

// Services Section
.section-services {
	padding: 30px 0;
	h1 {
		color: $c-brown;
	}
}
.service-boxes {
	.vid-box {
		display: none;
	}
	.service-box {
		width: 100%;
		height: 120px;
		background-position: center;
		background-repeat: no-repeat;
		margin: 2% 0;
		position: relative;
		overflow: hidden;
		&.weed {
			background-image: url('../img/gen/service-weed.jpg');
			background-size: cover;
		}
		&.aeration {
			background-image: url('../img/gen/service-aeration.jpg');
			background-size: cover;
		}
		&.seeding {
			background-image: url('../img/gen/service-seeding.jpg');
			background-size: cover;
		}
		&.fert {
			background-image: url('../img/gen/service-fert.jpg');
			background-size: cover;
		}
	}
	.box-title {
		color: white;
		position: relative;
		top: 10px;
		left: 10px;
		text-transform: uppercase;
		font-family: 'arsenal-bold';
	}
	.box-content {
		opacity: 0;
	}
}

.icon-play { // svg
	transition: .3s all;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	transform: translateY(240%);
	width: 90px;
	height: 90px;
	fill: #fff;
	stroke: #076930;
    stroke-linejoin: round;
    stroke-width: 2px;
    fill-rule: evenodd;
    opacity: 0.8;
}
.vid-text {
	transition: .3s all;
	text-transform: uppercase;
	color: white;
	position: absolute;
	left: 0;
	right: 0;
	font-family: 'arsenal-bold';
	font-size: 24px;
	text-align: center;
	top: 62%;
	opacity: 0;
}

// CTAs
.service-ctas {
	font-size: 0;
	font-family: 'arsenal-bold';
	color: $c-green;
	.cta {
		margin: 2% 0;
		padding: 10px;
		border: 1px solid #60462c;
		border-bottom-width: 2px;
		text-transform: uppercase;
		background: #eee9d1;
		font-size: 20px;
		display: inline-block;
		width: 100%;
		text-align: center;
		transition: .2s all;
		&:hover {
			background-color: #effdd3;
		}
	}
}
.arrow-right {
	width: 10px;
	height: 10px;
	path { fill: $c-brown; }
}


@include bp(eight-column) {
	.section-services {
		h1 {
			font-size: 3.5vw;
			text-align: center;
		}
	}
	.slider-content {
		display: block;
		width: 330px;
		bottom: 40px;
		font-family: 'arsenal-bold-italic';
		.slide-link {
			font-family: 'arsenal-bold';
		}
	}
	.service-boxes {
		.overlay {
			position: absolute;
			display: none;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background: rgba(#2b2115,.5);
			transition:  .3s ease-in-out;
			.overlay-content {
				position: relative;
				display: block;
				text-decoration: none;
				top: 50%;
				transform: translateY(-50%);
				text-align: center;
				font-weight: 300;
				padding: 0 20px;
				color: white;
				h1 {
					margin: 0;
					color: white;
					font-size: 26px;
					font-family: 'arsenal-bold';
				}
				p {
					color: white;
					font-size: 16px;
				}
				i {
					background: url('../svg/circle-arrow-right.svg') 0 0 no-repeat;
					background-size: contain;
					width: 30px;
					height: 30px;
					display: block;
					margin: 0 auto;
				}
			}
		}
		// Service boxes
		.vid-box {
			max-width: 500px;
			margin: 0 auto 40px;
			display: block;
			position: relative;
			&:hover {
				.icon-play {
					opacity: 1;
					stroke: none;	
				}
				.vid-text {
					opacity: 1;
				}
			}
		}
		.service-box {
			background-position: center;
			background-repeat: no-repeat;
			float: left;
			width: 48%;
			&.weed,&.fert {
				height: 280px;
			}
			&.aeration,&.seeding {
				height: 145px;
			}
			&.weed {
				margin: 0 4% 0 0;
			}
			&.aeration {
				margin: 0 0 4% 0;
			}
			&.seeding {
				margin: 6% 0 0 0;
			}
			&.fert {
				float: right;
			}
			.box-title {
				transition: .2s all;
			}
		}
		.box-title {
			color: white;
			position: relative;
			top: 20px;
			left: 20px;
			text-transform: uppercase;
		}
		.box-content {
			padding: 20px;
			color: white;
			margin: auto;
			position: absolute;
			bottom: 0;
			font-family: 'oxy-reg';
			line-height: 1.3;
			font-size: 14px;
		}
	}
	.icon-play {
		transform: translateY(170%);
	}
	// CTAs
	.service-ctas {
		.cta {
			max-width: 400px;
			margin: 15px auto;
			display: block;
		}
	}
}

@include bp(twelve-column) {
	.decor-grass-slider {
		display: block;
	}
	.section-slider {
		height: 550px;
	}
	.slide-item-home {
		height: 550px;
	}
	.slider-content {
		.slide-title {
			font-size: 36px;
		}
		.slide-link {
			font-size: 20px;
		}
	}
	.service-boxes {
		// Service boxes
		.vid-box {
			margin: 0 0 3% 2%;
			width: 460px;
			float: right;
			max-width: none;
		}
		.service-box {
			width: 22%;
			&.weed,&.fert {
				height: 210px;
			}
			&.seeding,&.aeration {
				height: 120px;
			}
			&.weed {
				margin: 0 3% 0 0;
			}
			&.aeration {
				margin: 0 0 2% 0;
			}
			&.seeding {
				margin: -140px 0 0 0;
			}
			&.fert {
				float: left;
			}
			&:hover {
				&.weed,&.fert {
					.box-title {
						transform: translateY(10px);
					}
				}
				&.aeration,&.seeding {
					.box-title {
						transform: translateY(-10px);
					}
					.box-content {
						transform: translateY(0);
					}
				}
				.box-content {
					opacity: 1;
					transition: .2s all;
					transform: translateY(-10px);
				}
			}
		}
		.box-title {
			color: white;
			position: relative;
			top: 20px;
			left: 20px;
			text-transform: uppercase;
		}
		.box-content {
			padding: 20px;
			color: white;
			margin: auto;
			position: absolute;
			bottom: 0;
		}
	}
	.vid-text {
		top: 67%;
	}
	// CTAs
	.service-ctas {
		font-size: 0;
		.cta {
			max-width: 400px;
			margin: 20px 0;
			display: inline-block;
			width: 32%;
			&:nth-of-type(1),&:nth-of-type(2) {
				margin-right: 2%;
			}
		}
	}
}

@include bp(sixteen-column) {
	.slider-1 {
		background-position: center top;
	}
	.slide-item-home {
		//height: 650px;
	}
	.section-services {
		h1 {
			font-size: 3vw;
		}
	}
	.slider-content {
		width: 400px;
		.slide-title {
			font-size: 40px;
		}
	}
	.service-boxes {
		// Service boxes
		.vid-box {
			margin: 0 0 3% 3%;
			width: 550px;
			float: right;
		}
		.box-title {
			font-size: 26px;
		}
		.service-box {
			width: 22%;
			&.weed,&.fert {
				height: 260px;
			}
			&.seeding,&.aeration {
				height: 145px;
			}
			&.weed {
				margin: 0 3% 0 0;
			}
			&.aeration {
				margin: 0 0 1.3% 0;
			}
			&.seeding {
				margin: 137px 0px 0 -520px;
			}
			&.fert {
				float: left;
			}
			&:hover {
				&.weed,&.fert {
					.box-title {
						transform: translateY(20px);
					}
					.box-content {
						transform: translateY(-30px);
					}
				}
				&.fert {
					.box-title {
						transform: translateY(10px);
					}
				}
				&.aeration,&.seeding {
					.box-title {
						transform: translateY(-30px);
					}
					.box-content {
						transform: translateY(-10px);
					}
				}
				&.seeding {
					.box-title {
						transform: translateY(-40px);
					}
				}
				.box-content {
					opacity: 1;
					transition: .2s all;
					transform: translateY(-10px);
				}
			}
		}
		.vid-text {
			top: 57%;
		}
		.box-title {
			color: white;
			position: relative;
			top: 50px;
			left: 20px;
			text-transform: uppercase;
		}
		.box-content {
			padding: 20px;
			color: white;
			margin: auto;
			position: absolute;
			bottom: 0;
		}
	}
}





