
/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
	position: relative;
	// margin: 0 auto 60px;
	// height: 100%;
	padding: 0;
	*zoom: 1;
}
.bx-viewport {
	height: 100% !important;
}

.bx-wrapper img {
	max-width: 100%;
	display: block;
}

/** THEME
===================================*/

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: 20px;
	left: 5%;
	width: auto;
	display: none;
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	background: url(../img/bxslider-assets/bx_loader.gif) center center no-repeat #fff;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* PAGER */

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: #d7d5dc;
	padding-top: 20px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
	background: #909094;
	opacity: .5;
	text-indent: -9999px;
	display: block;
	width: 10px;
	height: 10px;
	margin: 0 5px;
	outline: 0;
	border-radius: 5px;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
	background: #909094;
}

/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
	left: 120px;
	background: url(../img/bxslider-assets/controls.png) no-repeat 0 -32px;
}

.bx-wrapper .bx-next {
	right: 120px;
	background: url(../img/bxslider-assets/controls.png) no-repeat -43px -32px;
}

.bx-wrapper .bx-prev:hover {
	background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
	background-position: -43px 0;
}

.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 16px;
	margin-top: -16px;
	outline: 0;
	width: 50px;
	height: 50px;
	//text-indent: -9999px;
	font-size: 16px;
	color: $c-tan;
	z-index: 9999;
	background: none;
}

.bx-wrapper .bx-controls-direction a.disabled {
	display: none;
}


/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}




@include bp(eight-column) {
	.bx-wrapper .bx-pager,
	.bx-wrapper .bx-controls-auto {
		display: block;
	}
		
}

@include bp(twelve-column) {
	
}

@include bp(sixteen-column) {

}

