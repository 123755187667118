body {
	transition: .2s all;
	&.isMoved {
		transform: translateX(-230px);
		overflow: visible;
		position: absolute;
		.touch-target {
			position: fixed;
			height: auto;
			left: -400px;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(0,0,0,0.4);
			box-shadow: -2px 0 3px 4px rgba(0,0,0,0.2);
		}
	}
}
header {
	position: relative;
	.wrapper {
		max-width: none;
		padding: 0;
	}
}
.logo-header {
	background: $c-green url('../svg/fairway-logo.svg') 8px center no-repeat;
	background-size: 100%;
	width: 190px; //365x105
	height: 70px;
	float: left;
	fill: white;
	position: relative;
	z-index: 999;
	transition: .2s all;
	a {
		display: block;
		height: 100%;
	}
}

.secondary-nav {
	display: none;
}
.eyebrow {
	display: none;
	font-family: 'oxy-bold';
	clear: both; // to hide the overflow when it's being toggled on mobile by the call button
	text-align: center;
	background: $c-green;
	color: white;
	padding: 10px 0;
	.phone-num {
		margin: 5px 0;
	}
}
.triggers {
	float: right;
	position: relative;
	z-index: 99;
	margin: 5px 5px 8px 0;
	font-family: 'arsenal-bold';
	font-size: 12px;
	text-transform: uppercase;
	line-height: 1;
	color: $c-tan;
	//border: 1px solid $c-tan;
	padding: 10px;
}
.trigger {
	width: 25px;
	height: 25px;
	display: block;
	cursor: pointer;
	fill: $c-brown;
	background-size: contain;
}
.nav-trigger-container,.call-trigger-container {
	position: relative;
}
.nav-trigger-container {
	width: 33px;
	float: right;
	margin-left: 30px;
}
.nav-trigger {
	margin: 0 auto;
}
.touch-target {
	position: absolute;
	left: -15px;
	right: -10px;
	top: -10px;
	bottom: -10px;
	z-index: 999;
}
.call-trigger-container {
	float: left;
	margin: 0 0 0 0;
	width: 27px;
	&:after {
		content: "";
		display: block;
		width: 1px;
		background-color: #eee9d1;
		height: 40px;
		position: absolute;
		right: -14px;
		top: -2px;
	}
}
.portal-trigger-container {
	float: left;
	margin-right:20px;
	img {
		width:26px;
		margin:0 auto;
		display:block;
	}
	&:after {
		content: "";
		display: block;
		width: 1px;
		background-color: #eee9d1;
		height: 40px;
		position: absolute;
		left: 58px;
		top: 7px;
	}
}
.call-trigger {
	height: 20px;
	margin: 2px 0 2px;
	transform: rotate(-11deg);
}
.decor-grass-header {
	display: none;
	background: url('../svg/decor-lines-header.svg') 0 0 no-repeat;
	background-size: 100%;
	width: 100%;
	height: 40px;
	position: absolute;
	z-index: 9;
	top: 90px;
}




@include bp(eight-column) {
	body.isMoved {
		.touch-target {
			left: -800px;
			right: 0;
		}
	}
	header {
		.wrapper {
			padding: 0 20px;
		}
	}
	.logo-header {
		width: 300px;
		height: 105px;
	}
	.eyebrow {
		display: inline-block;
		padding: 0;
		top: 30px;
		left: 20px;
		position: relative;
		background: transparent;
		color: $c-brown;
		.phone-num {
			color: $c-black;
			.num {
				color: $c-green;
			}
		}
	}
	.triggers {
		margin: 10px 15px 0 0;
		border: none;
	}
	.trigger {
		width: 50px;
		height: 50px;
	}
	.nav-trigger-container {
		font-size: 19px;
		width: auto;
	}
	.call-trigger-container {
		display: none;
	}
	.portal-trigger-container {
	float: left;
	margin-right:0px;
		&:after {
			content: "";
			display: block;
			width: 1px;
			background-color: #ffffff;
			height: 40px;
			position: absolute;
			left: 58px;
			top: 7px;
		}
	}

}

@media screen and (min-width: 1120px) {
	.portal-trigger-container {
		display: none;
	}
	.decor-grass-header {
		display: block;
	}
	.triggers { display: none; }
	.eyebrow {
		top: 20px;
		left: auto;
		right: 20px;
		// position: relative;
		position: absolute;
		.phone-num {
			display: inline-block;
			margin-left: 15px;
			font-size: 20px;
		}
	}
	.secondary-nav {
		display: block;
		position: absolute;
		background-color: white;
		right: 20px;
		bottom: 25px;
		text-transform: uppercase;
		font-family: 'arsenal-bold';
		color: #b4d57f;
		.sec-nav-item {
			display: inline-block;
			position: relative;
			color: #679727;
			&:hover {
				color: #17120c;
			}
			i {
				display: block;
				position: absolute;
				left: -35px;
				width: 30px;
				height: 30px;
			}
			&:last-of-type {
				margin-left: 50px;
			}
			&.pay i {
				background: url('../svg/icon-credit-card.svg') 0 0 no-repeat;
				background-size: contain;
			}
			&.contact i {
				background: url('../svg/icon-contact.svg') 0 0 no-repeat;
				background-size: contain;
			}
		}
	}
}

// @include bp(sixteen-column) {
// 	.eyebrow {
// 		top: 10px;
// 		.phone-num {
// 			font-size: 25px;
// 		}
// 	}
// }














