.slider-form {
    position: relative;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s all;
    &.active {
        max-height: 1000px;
    }
}
.form-intro-text {
    font-family: "oxy-reg";
    font-style: italic;
    color: darken($c-tan, 15%);
    line-height: 1;
    display: block;
    margin: 10px 5px 0;
    font-size: 10px;
    text-align: center;
}
.form-trigger {
    .page-template-default & {
        display: none;
    }
    background-color: $c-brown;
    border: 1px solid #679727;
    border-bottom-width: 5px;
    text-align: left;
    font-size: 22px;
    outline: none;
    display: block;
    width: 100%;
    margin: auto;
    font-family: "arsenal-bold";
    position: relative;
    top: 20px;
    max-width: 95%;
    color: white;
    padding: 15px;
    text-transform: uppercase;
    z-index: 99;
    outline-color: darken($c-green, 10%);
    span {
        display: block;
    }
    &:after {
        content: "+" !important;
        font-family: "arsenal-bold";
        font-size: 60px;
        position: absolute;
        right: 15px;
        top: 2px;
        display: block;
        color: #c1f74e;
    }
    .arrow-down {
        fill: white;
        width: 20px;
        height: 10px;
        margin-right: 5px;
        transition: 0.3s all;
    }
    &.active {
        .arrow-down {
            transform: rotate(180deg);
        }
    }
}
.form-header {
    display: none;
    text-transform: uppercase;
    text-align: center;
    font-family: "arsenal-bold";
    line-height: 1;
    margin: 0;
    font-size: 26px;
    color: $c-green;
}
.contact-form {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 20px 0;
    font-family: "oxy-reg";
    background-color: #222;
    .form-group {
        position: relative;
        top: 25px;
        padding: 0 0 20px 0;
    }
    fieldset {
        margin: 20px 0;
        border: none;
        position: relative;
        @include pie-clearfix;
    }
    fieldset.label-up {
        label {
            top: -18px;
            color: white;
        }
    }
    .fieldset-group {
        @include pie-clearfix;
        &:last-of-type {
            margin-top: 20px;
        }
        .city,
        .phone {
            width: 60%;
        }
        .state,
        .zip {
            width: 40%;
        }
        fieldset {
            float: left;
            margin: 0;
        }
    }
    label {
        position: absolute;
        font-size: 16px;
        color: $c-green;
        text-align: right;
        top: 10px;
        left: 20px;
        z-index: 2;
    }
    input {
        width: 100%;
        padding: 7px;
        outline: none;
        height: 45px;
        border: none;
        color: $c-brown;
        background-color: #fff;
        // border: 2px solid #60462c;
        border: 2px solid $c-green;
        // &:focus {
        // 	border-color: #22aced;
        // }
    }

    .styled-select {
        width: 100%;
        height: 45px;
        overflow: hidden;
        background: white url(../svg/arrow-dropdown.svg) 90% 50% no-repeat;
        border: 2px solid $c-green;
        color: $c-green;
        select {
            background: transparent;
            width: 100%;
            height: 100%;
            color: $c-green;
            outline: none;
            padding: 5px;
            font-size: 16px;
            line-height: 1;
            border: 0;
            border-radius: 0;
            -webkit-appearance: none;
        }
    }
    textarea {
        width: 100%;
        height: 90px;
        border: 1px solid #ccc;
        outline: none;
        &:focus {
            border-color: #22aced;
        }
    }
    input[type="submit"],
    .submit {
        border: 1px solid $c-green;
        // height: 45px;
        // line-height: 45px;
        padding: 10px 20px;
        cursor: pointer;
        width: 100%;
        text-align: center;
        color: $c-green;
        text-transform: uppercase;
        background: white;
        transition: 0.2s all;
        font-size: 22px;
        font-family: "arsenal-bold";
        &:hover {
            background: #effdd3;
            border-color: #b4d57f;
        }
        &:active {
            background: $c-green;
            color: white;
        }
    }
}
.form-internal {
    fieldset.label-up {
        label {
            color: $c-tan;
        }
    }
}
.btn-get-started {
    transition: 0.2s all;
    &:hover {
        background: #effdd3;
        border-color: #b4d57f;
        .arrow-right {
            fill: #b4d57f;
        }
    }
    &:active {
        background: $c-green;
        color: white;
    }
    .arrow-right {
        position: relative;
        top: -2px;
    }
}

.sidebar #bx-pager,
.slider-form #bx-pager {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    text-align: center;
    color: $c-tan;
    font-family: "arsenal-bold-italic";
    .active {
        text-decoration: underline;
    }
    a:first-of-type {
        margin-right: 10px;
    }
}
.styledRadio {
    display: inline-block;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 10px auto;
    &:first-of-type {
        //margin-right: 29px;
    }
}
fieldset.radios {
    font-size: 0;
    margin: 15px auto;
    display: block;
    width: 310px;
    label {
        margin-top: -30px;
        left: 10px;
        color: white;
    }
    span {
        font-size: 16px;
        position: absolute;
        color: white;
    }
}
fieldset.newsletter {
    label {
        left: 0;
        right: 0;
        text-align: center;
        line-height: 1;
        color: white;
    }
    .styledCheckbox {
        position: relative;
        left: -135px;
        margin: 0 auto;
    }
}

.error-container {
    max-width: 640px;
    margin: 0 auto;
    background: rgba(red, 0.6);
    border: 2px solid red;
    padding: 15px;
    .section-title-error {
        font-size: 30px;
        color: white;
    }
    ul {
        color: white;
    }
}

// INTERNAL SIDEBAR FORM STYLES
.body-container {
    .form-trigger {
        top: -10px;
    }
    .form-header {
        font-size: 22px;
        background-color: $c-green;
        padding: 15px;
        display: block;
        color: white;
    }
    .contact-form {
        background-color: #eee9d1;
        padding: 0;
        max-width: 480px;
        input,
        textarea,
        .styled-select {
            background-color: #fff;
            border-color: #b1a085;
            color: #b1a085;
        }
        .styled-select select {
            color: #b1a085;
        }
        label {
            color: #b1a085;
        }
    }
    .styledRadio {
        background-size: 100%;
        margin: 10px auto 0;
        display: block;
    }
    #bx-pager {
        top: 112px;
    }
}
.slider-form {
    #bx-pager {
        left: 0;
        right: 0;
        text-align: center;
        position: absolute;
        color: #ada680;
        font-family: "arsenal-bold-italic";
    }
}
.sidebar {
    // max-height: 0;
    // opacity: 0;
    overflow: hidden;
    transition: 0.3s all;
    &.active {
        max-height: 1000px;
        opacity: 1;
    }
}

@include bp(eight-column) {
    .form-trigger {
        max-width: 520px;
        span {
            display: inline;
        }
        &:after {
            top: -10px;
        }
    }
}

@include bp(twelve-column) {
    .form-header {
        display: block;
        color: white;
        font-size: 32px;
    }
    .form-trigger {
        display: none;
    }
    .slider-form {
        display: block;
        max-height: 1000px;
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(#2b2115, 0.8);
        width: 400px;
        padding: 0 20px;
        height: 100%;
        #bx-pager {
            top: 90px;
            position: absolute;
        }
    }
    .contact-form {
        width: 100%;
        background-color: transparent;
        .go-back {
            display: none;
        }
        fieldset:not(.submit-fieldset) {
            padding: 0.35em 0.625em 0.35em;
            margin: 5px 0 0 0;
        }
        fieldset.submit-fieldset {
            margin: 10px 0;
        }
        fieldset.label-up {
            label {
                top: 10px;
                font-style: italic;
                color: #8fc439;
            }
            input,
            textarea {
                border-bottom-color: #8fc439;
            }
        }
        .fieldset-group {
            &:last-of-type {
                margin-top: 10px;
            }
        }
        input,
        .styled-select,
        textarea {
            background-color: #17120c;
            border: none;
            // border-bottom: 3px solid #8fc439;
            border-bottom: 3px solid lighten($c-brown, 5%);
            color: white;
        }
        .textarea {
            padding-bottom: 0;
            margin-bottom: 0;
        }
        input.user_name {
            text-indent: 90px;
        }
        input.user_address {
            text-indent: 80px;
        }
        input.user_city {
            text-indent: 50px;
        }
        input.user_zip {
            text-indent: 40px;
        }
        input.user_phone {
            text-indent: 60px;
        }
        input.user_email {
            text-indent: 60px;
        }
        label {
            color: white;
        }
        textarea {
            padding: 40px 0 0 10px;
            line-height: 1;
        }
        .styled-select {
            select {
                color: white;
            }
        }
        fieldset.radios {
            font-size: 0;
            margin-top: 15px;
            width: auto;
            label {
                margin-top: -23px;
                left: 10px;
            }
        }
        fieldset.newsletter {
            label {
                left: 0;
                right: 0;
                text-align: center;
                line-height: 1;
            }
            .styledCheckbox {
                position: relative;
                left: 15px;
                margin: 0;
            }
        }
    }
    .form-internal {
        max-height: 1000px;
        opacity: 1;
        display: block;
    }
    .styledRadio {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 20px 0 15px 7px;
        &:first-of-type {
            margin-right: 29px;
        }
    }

    // INTERNAL SIDEBAR FORM STYLES
    .body-container {
        .styledRadio {
            display: inline-block;
            margin: 20px 0 0;
            background-size: 100%;
            &:first-of-type {
                margin-right: 5px;
                margin-left: 10px;
            }
        }
        fieldset.newsletter label {
            left: 20px;
        }
        #bx-pager {
            top: 112px;
        }
    }
    .sidebar {
        max-height: 1000px;
        opacity: 1;
    }
}

@include bp(sixteen-column) {
    .form-internal {
        .form-group {
            padding: 25px;
        }
    }
}
